'use client';

import ErrorLayout from './error-layout';
import NotFound from 'next/dist/client/components/not-found-error';

export default function Error({}: { error: Error; reset: () => void }) {
  return (
    <ErrorLayout>
      <NotFound />
    </ErrorLayout>
  );
}
